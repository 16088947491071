export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en santé sexuelle et reproductive"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Santé Sexuelle et Reproductive"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez expert(e) en santé sexuelle et reproductive avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme vous permet d'acquérir les compétences essentielles pour intervenir en tant qu’expert(e) en santé sexuelle et reproductive au niveau individuel, communautaire et institutionnel."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approches pluridisciplinaires"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer des connaissances pluridisciplinaires en santé sexuelle et reproductive pour l’intégrer dans sa pratique professionnelle"])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techniques d’intervention"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquérir les techniques communicationnelles mises en œuvre dans le cadre d'une activité d'éducation ou d'intervention de soutien liée à la sexualité."])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversités"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distinguer les différents facteurs individuels et collectifs influençant les croyances, les perceptions, et les comportements associés à la sexualité"])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche biopsychosociale"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer une approche biopsychosociale de la sexualité humaine"])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violences liées au genre"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articuler santé sexuelle et lutte contre les violences liées au genre"])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexualité"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuter avec aisance des divers enjeux de société actuels reliés à la sexualité"])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins, chirurgiens, infirmiers, sages-femmes, pharmaciens, paramédicaux."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de l’éducation, travailleurs des services sociaux, psychologues, journalistes, responsables d’associations, personnels d’organisations à but non lucratif, professionnels du secteur humanitaire, activistes sociaux, acteurs des politiques de santé publique, etc."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique flexible et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode synchrone (classe virtuelle) et asynchrone (cours pré-enregistrés)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux et de professeurs des universités en sciences de la santé, en sciences sociales et humaines."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 heures de cours"])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle cohorte est lancée à chaque début de mois."])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’étudiant est soumis à une épreuve de contrôle de connaissances en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel en santé sexuelle et reproductive qui est délivré aux étudiants ayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de l’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures pertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de finaliser leur inscription."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}